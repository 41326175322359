import { apiConfig } from "@/ApiConfig";
import type { ids } from "@seekdharma/common-types";
import { Fetch } from "@seekdharma/std";
import { talentApi } from "@seekdharma/studio-shared";

export const TalentApi = (talentId: ids.TalentId) => {
  const fetch = Fetch(`${apiConfig.basePath}/talent/${talentId}`, () => ({
    headers: { authorization: apiConfig.getToken() },
  }));
  return talentApi.Client(fetch);
};
