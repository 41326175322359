<script lang="ts">
  import AnonymousPortal from "./anonymous-portal/AnonymousPortal.svelte";
  import type { AppModel } from "./App";
  import { getAppContext } from "./AppContext";
  import AuthPortal from "./auth-portal/AuthPortal.svelte";
  import AuthenticatedApp from "./AuthenticatedApp.svelte";
  import { setRouterContext } from "./lib/router-svelte";

  export let app: AppModel;
  const { history } = getAppContext();
  setRouterContext({ history, basePath: "" });

  $: void app.init();
  $: active = app.view;
</script>

{#if $active.name === "AnonymousPortal"}
  <AnonymousPortal model={$active.value} />
{:else if $active.name === "AuthPortal"}
  <AuthPortal model={$active.value} />
{:else}
  <AuthenticatedApp app={$active.value} />
{/if}

<style global lang="postcss">
  @import "./main.css";
</style>
