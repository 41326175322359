<script lang="ts">
  import Loader from "@/components/Loader.svelte";
  import { RD } from "@seekdharma/std";
  import { PageWithCover } from "../ui-kit";
  import type { AuthPortal } from "./AuthPortal";
  import PageContent from "./components/PageContent.svelte";
  import EnterLoginCodeFormPage from "./pages/EnterLoginCodeFormPage.svelte";
  import RequestLoginCodeFormPage from "./pages/RequestLoginCodeFormPage.svelte";
  import SignUpPage from "./pages/SignUpPage.svelte";

  export let model: AuthPortal;

  $: loginState = model.loginState;
  $: route = model.route;
  $: canSignUp = model.canSignUp;

  /* eslint-disable */
  const goToSignIn = () => model.showRequestCodeForm($route.email);
  const goToSignUp = () => model.showSignUpForm($route.email);
</script>

<PageWithCover cover="/images/auth-portal-hero.jpg">
  {#if RD.isInitial($loginState) || RD.isFailure($loginState)}
    {#if $route.name === "signUp"}
      <PageContent
        class="p-m"
        context="signUp"
        canSignUp
        on:signIn={goToSignIn}
        on:signUp={goToSignUp}
      >
        <SignUpPage model={$route.model} />
      </PageContent>
    {:else if $route.name === "requestLoginCode"}
      <PageContent
        class="p-m"
        context="signIn"
        {canSignUp}
        on:signIn={goToSignIn}
        on:signUp={goToSignUp}
      >
        <RequestLoginCodeFormPage model={$route.model} />
      </PageContent>
    {:else}
      {@const { email } = $route}
      <PageContent
        class="p-m"
        hero="Authenticate"
        subheading="A code was sent to {email}"
      >
        <EnterLoginCodeFormPage
          model={$route.model}
          goBack={() => model.showRequestCodeForm(email)}
        />
      </PageContent>
    {/if}
  {:else}
    <Loader />
  {/if}
</PageWithCover>
