import { pipe, RD, TE } from "@seekdharma/std";
import { writable, type Readable } from "svelte/store";
import { getAppContext, type AppContext } from "../AppContext";
import type { Auth } from "../Auth";
import { AuthApi } from "./AuthApi";
import { EnterLoginCodeFormPage } from "./pages/EnterLoginCodeFormPage";
import { RequestLoginCodeFormPage } from "./pages/RequestLoginCodeFormPage";
import { SignUpPage } from "./pages/SignUpPage";

export interface AuthPortal {
  route: Readable<AuthPortalRoute>;
  loginState: Readable<RD.RemoteData<unknown, void>>;
  canSignUp: boolean;

  showSignUpForm: (email?: string) => void;
  showRequestCodeForm: (email?: string) => void;
  showEnterCodeForm: (
    email: string,
    context: EnterLoginCodeRoute["context"],
  ) => void;
}

type Deps = {
  auth: Pick<Auth, "login" | "loginState">;
  authApi?: AuthApi;
  appContext?: Pick<AppContext, "tracker">;
};

export const AuthPortal = ({ auth, authApi = AuthApi() }: Deps): AuthPortal => {
  const { tracker, history } = getAppContext();
  const searchParams = new URLSearchParams(history.location.search);
  const route = writable<AuthPortalRoute>();
  const portal: AuthPortal = {
    route,
    loginState: auth.loginState,
    canSignUp: searchParams.has("signup-as-talent"),

    showRequestCodeForm: (email?: string) => {
      route.set({
        name: "requestLoginCode",
        email,
        model: RequestLoginCodeFormPage({
          email,
          requestCode: (email) =>
            pipe(
              authApi.requestCode(email),
              TE.chainFirstIOK(
                () => () => portal.showEnterCodeForm(email, "signIn"),
              ),
            ),
        }),
      });
    },

    showEnterCodeForm: (email, context) => {
      route.set({
        name: "enterLoginCode",
        email,
        context,
        model: EnterLoginCodeFormPage({
          email,
          login: auth.login,
          requestCode: authApi.requestCode,
        }),
      });
    },

    showSignUpForm: (email) => {
      if (!portal.canSignUp) return;
      route.set({
        name: "signUp",
        email,
        model: SignUpPage({
          email,
          signUp: (values) =>
            pipe(
              authApi.signUp(values),
              TE.chainFirstIOK(
                () => () => portal.showEnterCodeForm(values.email, "signUp"),
              ),
            ),
        }),
      });
    },
  };
  portal.canSignUp ? portal.showSignUpForm() : portal.showRequestCodeForm();
  return portal;
};

export type AuthPortalRoute =
  | RequestLoginCodeRoute
  | EnterLoginCodeRoute
  | SignUpFormRoute;

type RequestLoginCodeRoute = {
  name: "requestLoginCode";
  email?: string;
  model: RequestLoginCodeFormPage;
};

type EnterLoginCodeRoute = {
  name: "enterLoginCode";
  model: EnterLoginCodeFormPage;
  email: string;
  context: "signUp" | "signIn";
};

type SignUpFormRoute = {
  name: "signUp";
  email?: string;
  model: SignUpPage;
};
