import { isUlid } from "@seekdharma/std";

export interface Tracker {
  pageView: (path: string) => void;
}

class TrackerImpl implements Tracker {
  constructor(private dataLayer: DataLayer) {}
  pageView = (path: string) => {
    this.dataLayer.push({
      event: "virtualPageView",
      pageURL: this.#mapUlidSegments(path),
      Source: "studio",
    });
  };

  #mapUlidSegments(path: string) {
    return path
      .split("/")
      .map((segment) => (isUlid(segment) ? ":ulid" : segment))
      .join("/");
  }
}

type DataLayer = TrackerEvent[];
type TrackerEvent = {
  event: "virtualPageView";
  pageURL: string;
  Source: "studio";
};

class TestTrackerImpl extends TrackerImpl {
  #events: TrackerEvent[];

  constructor() {
    const events = [] as TrackerEvent[];
    super(events);
    this.#events = events;
  }

  get events(): TrackerEvent[] {
    return this.#events;
  }
}

export const Tracker = (dataLayer: unknown[]) =>
  new TrackerImpl(dataLayer as DataLayer);

export const TestTracker = () => new TestTrackerImpl();
