import { O, pipe } from "@seekdharma/std";
import { createPath, type Location } from "history";
import { derived, get, writable, type Readable } from "svelte/store";
import { AnonymousPortal } from "./anonymous-portal/AnonymousPortal";
import { clearAppContext, setAppContext, type AppContext } from "./AppContext";
import type { Auth } from "./Auth";
import { AuthPortal } from "./auth-portal/AuthPortal";
import type { Me } from "./auth-portal/Me";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { findFirstScrollableElementInViewport } from "./lib/scroll";

export type AppModel = {
  readonly view: Readable<View>;

  init: () => Promise<void>;
  destroy: () => void;
  logout: () => Promise<void>;
};

type View =
  | { name: "AnonymousPortal"; value: AnonymousPortal }
  | { name: "AuthenticatedApp"; email: string; value: AuthenticatedApp }
  | { name: "AuthPortal"; value: AuthPortal };

type AuthBasedView = Extract<View, { name: "AuthenticatedApp" | "AuthPortal" }>;

type Deps = {
  auth: Auth;
  context: AppContext;
  anonymousPortal?: AnonymousPortal; // for testing purposes only
};

export const AppModel = ({
  auth,
  context,
  anonymousPortal,
}: Deps): AppModel => {
  setAppContext(context);
  anonymousPortal ||= AnonymousPortal();

  const trackPage = (location: Location) => {
    context.tracker.pageView(createPath(location));
  };
  const unsubscribeFromHistoryChanges = context.history.listen((update) => {
    trackPage(update.location);
    const scrollableArea = findFirstScrollableElementInViewport() ?? window;
    scrollableArea.scrollTo({ left: 0, top: 0, behavior: "auto" });
  });

  const app: AppModel = {
    view: ActiveView(auth, anonymousPortal),
    init: async () => {
      trackPage(context.history.location);
      await Promise.all([auth.init(), context.config.load()]);
    },
    destroy: () => {
      clearAppContext();
      unsubscribeFromHistoryChanges();
    },
    logout: auth.logout,
  };

  return app;
};

const ActiveView = (auth: Auth, anonymousPortal: AnonymousPortal) => {
  const authBasedView: Readable<AuthBasedView> = derived(
    auth.me,
    (me, set) => {
      const current = get(authBasedView);
      const next = getNextAuthBasedView(current, me);
      if (next) set(next);
    },
    AuthPortalView(),
  );
  const view = derived(
    [anonymousPortal.route, authBasedView],
    ([anonymousRoute, appView]) =>
      pipe(
        anonymousRoute,
        O.map(AnonymousPortalView),
        O.getOrElseW(() => appView),
      ),
  );
  return view;

  function AuthPortalView(): AuthBasedView {
    return {
      name: "AuthPortal",
      value: AuthPortal({ auth }),
    };
  }

  function AnonymousPortalView(): View {
    return {
      name: "AnonymousPortal",
      value: anonymousPortal,
    };
  }

  function AuthenticatedAppView(me: Me): AuthBasedView {
    return {
      name: "AuthenticatedApp",
      email: me.email,
      value: AuthenticatedApp({ auth, me: writable(me) }),
    };
  }

  function getNextAuthBasedView(
    current: AuthBasedView,
    me: O.Option<Me>,
  ): AuthBasedView | undefined {
    switch (current.name) {
      case "AuthenticatedApp":
        return O.isNone(me)
          ? AuthPortalView()
          : me.value.email !== current.email
            ? AuthenticatedAppView(me.value)
            : undefined;

      case "AuthPortal":
        return O.isSome(me) ? AuthenticatedAppView(me.value) : undefined;
    }
  }
};
