import Hotjar from "@hotjar/browser";

export type Hotjar = Pick<typeof Hotjar, "identify">;
export const initHotjar = (): Hotjar => {
  const siteId = process.env.HOTJAR_SITE_ID as unknown as number;
  const version = process.env.HOTJAR_VERSION as unknown as number;

  Hotjar.init(siteId, version, {
    debug:
      new URLSearchParams(window.location.search).get("debugHotjar") === "true",
  });

  return {
    identify: Hotjar.identify.bind(Hotjar),
  };
};

export const initHotjarMock = (): Hotjar & {
  users: Map<string, Record<string, unknown>>;
} => {
  const users = new Map<string, Record<string, unknown>>();
  return {
    get users() {
      return users;
    },
    identify: (userId, userInfo) => {
      if (!userId) return false;
      users.set(userId, userInfo);
      return true;
    },
  };
};
