<script lang="ts">
  import { O, pipe } from "@seekdharma/std";
  import { onDestroy } from "svelte";
  import type {
    AuthenticatedApp,
    AuthenticatedAppView,
  } from "./AuthenticatedApp";
  import SelectProfile from "./components/SelectProfile.svelte";
  import { LoadModuleFailed } from "./lib/error";
  import { setRouterContextBasePath } from "./lib/router-svelte";
  import {
    CrucialError,
    MinimalPageLayout,
    NotFound,
    PageWithCover,
  } from "./ui-kit";

  export let app: AuthenticatedApp;
  $: app.init();
  onDestroy(app.destroy);

  type PortalComponentMap = Record<
    AuthenticatedAppView["name"],
    () => Promise<{ default: ConstructorOfATypedSvelteComponent }>
  >;

  const getPortal = (
    name: AuthenticatedAppView["name"],
  ): Promise<{ default: ConstructorOfATypedSvelteComponent }> =>
    PortalComponent[name]();

  const PortalComponent = {
    admin: () =>
      Promise.reject(new Error("admin portal is yet to be implemented")),
    brandManager: () =>
      import("./employee/brand-manager-portal/BrandManagerPortal.svelte"),
    finance: () => import("./employee/finance-portal/FinancePortal.svelte"),
    productOwner: () =>
      import("./employee/product-owner-portal/ProductOwnerPortal.svelte"),
    purchaseManager: () =>
      import("./employee/purchase-manager-portal/PurchaseManagerPortal.svelte"),
    inventoryAndPaymentCoordinator: () =>
      import(
        "./employee/inventory-and-payment-coordinator-portal/InventoryAndPaymentCoordinatorPortal.svelte"
      ),
    quickAccess: () => import("./quick-access-portal/QuickAccessPortal.svelte"),
    talent: () => import("./talent-portal/TalentPortal.svelte"),
    tripPlanner: () =>
      import("./employee/trip-planner-portal/TripPlannerPortal.svelte"),
  } satisfies PortalComponentMap;

  $: active = app.active;
  $: me = app.me;

  /* eslint-disable */
  $: pipe(
    $active,
    O.map((route) => route.basePath),
    O.getOrElse(() => "/"),
    setRouterContextBasePath,
  );

  const asAny = (value: unknown) => value as any;
</script>

{#if O.isNone($active)}
  <PageWithCover cover="/images/auth-portal-hero.jpg">
    {#if $me.profiles.length === 0}
      <NotFound homeHref="/" />
    {:else}
      <main class="narrower flex column gap-m py-xl">
        <h3 class="text-center">Select a profile</h3>
        <div class="flex column gap-m">
          <SelectProfile optionClass="rounded" />
        </div>
      </main>
    {/if}
  </PageWithCover>
{:else}
  {@const { value: active } = $active}
  {#await Promise.all([getPortal(active.name), active.model()])}
    <MinimalPageLayout>
      <div class="flex column gap-m text-center py-xl">
        <div class="h3 placeholder" />
        <div class="placeholder" />
        <div class="placeholder" />
      </div>
    </MinimalPageLayout>
  {:then [Component, model]}
    <svelte:component this={Component.default} model={asAny(model)} />
  {:catch error}
    <PageWithCover cover="/images/auth-portal-hero.jpg">
      <div class="py-xl flex align-center gap-m">
        <CrucialError class="text-center" error={new LoadModuleFailed(error)} />
      </div>
    </PageWithCover>
  {/await}
{/if}
