import { ulid } from "@seekdharma/std";
import { createBrowserHistory } from "history";
import { AppModel } from "./App";
import AppComponent from "./App.svelte";
import { initTrackingId } from "./AppContext";
import { Auth } from "./Auth";
import { DestinationApi } from "./components/destination/Api";
import { PassionApi } from "./components/passion/PassionApi";
import { Config } from "./Config";
import { ConfigApi } from "./ConfigApi";
import { LocalStorageRefreshTokenPersistor } from "./infrastructure/LocalStorageRefreshTokenPersistor";
import { initHotjar } from "./lib/hotjar";
import { Tracker } from "./lib/Tracker";
import { ErrorReporter } from "./telemetry";

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const auth = Auth({
  persistor: LocalStorageRefreshTokenPersistor(),
});

const redirectSoftlyFromHashRoute = (location = window.location) => {
  if (!location.hash) return;
  const url = new URL(location.hash.slice(1), location.href); // handle in-hash search params
  // re-add out-of-hash search params
  for (const [key, value] of new URLSearchParams(location.search).entries()) {
    url.searchParams.append(key, value);
  }
  window.history.replaceState(null, "", url);
};

redirectSoftlyFromHashRoute();

const sessionId = initTrackingId("sessionId", sessionStorage, ulid);
const deviceId = initTrackingId("deviceId", localStorage, ulid);

const component = new AppComponent({
  target: document.body,
  props: {
    app: AppModel({
      auth,
      context: {
        history: createBrowserHistory(),
        tracker: Tracker(window.dataLayer),
        config: Config({ api: ConfigApi() }),
        errorReporter: ErrorReporter(deviceId, sessionId, auth.me),
        sessionId,
        deviceId,
        hotjar: initHotjar(),
        destinationApi: DestinationApi(),
        passionApi: PassionApi(),
      },
    }),
  },
});

export default component;
