import { mapRemoteData, pipe, RD, TE } from "@seekdharma/std";
import { writable, type Readable, type Writable } from "svelte/store";

export interface EnterLoginCodeFormPage {
  readonly email: string;
  readonly code: Writable<string>;
  readonly submitState: Readable<RD.RemoteData<unknown, unknown>>;
  readonly resendState: Readable<RD.RemoteData<unknown, unknown>>;
  submit: (code: string) => Promise<void>;
  resend: () => Promise<void>;
}

type Deps = {
  email: string;
  login: (email: string, code: string) => TE.TaskEither<unknown, void>;
  requestCode: (email: string) => TE.TaskEither<unknown, unknown>;
};

export const EnterLoginCodeFormPage = ({ email, login, requestCode }: Deps) => {
  const submitState = writable<RD.RemoteData<unknown, void>>(RD.initial);
  const resendState = writable<RD.RemoteData<unknown, unknown>>(RD.initial);
  const page: EnterLoginCodeFormPage = {
    email,
    code: writable(""),
    submitState,
    resendState,
    submit: async (code: string) => {
      await pipe(login(email, code), mapRemoteData(submitState.set))();
    },
    resend: async () => {
      await pipe(requestCode(email), mapRemoteData(resendState.set))();
    },
  };
  return page;
};
