import { apiConfig } from "@/ApiConfig";
import type { ids } from "@seekdharma/common-types";
import { Fetch } from "@seekdharma/std";
import { talentTripBriefApi as api } from "@seekdharma/studio-shared";

export const TalentTripBriefApi = (
  talentId: ids.TalentId,
): api.TalentTripBriefApi => {
  const fetch = Fetch(`${apiConfig.basePath}/talent/${talentId}/brief`, () => ({
    headers: { Authorization: apiConfig.getToken() },
  }));
  return api.Client(fetch);
};
