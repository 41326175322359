import {
  constVoid,
  flow,
  mapRemoteData,
  O,
  pipe,
  RD,
  TE,
} from "@seekdharma/std";
import {
  derived,
  get,
  writable,
  type Readable,
  type Writable,
} from "svelte/store";
import { apiConfig as globalApiConfig, type ApiConfig } from "./ApiConfig";
import { AuthApi, MeApi, type Me } from "./auth-portal";
import type { Credentials } from "./auth-portal/Credentials";
import type { RefreshTokenPersistor } from "./auth-portal/RefreshTokenPersistor";
import {
  TokenRefresher,
  type TokenRefreshDelegate,
} from "./auth-portal/TokenRefresher";
import { PeriodicScheduler } from "./infrastructure/PeriodicScheduler";
import type { RecoverableError } from "./lib/error";
import { lookup } from "./lib/fp-ts";

export interface Auth {
  me: Readable<O.Option<Me>>;
  loginState: Readable<RD.RemoteData<unknown, void>>;
  token: Readable<O.Option<string>>;

  init: (location?: { search: string }) => Promise<void>;
  login: (email: string, code: string) => TE.TaskEither<unknown, void>;
  renew: () => Promise<void>;
  logout: () => Promise<void>;
}

type RequiredDeps = {
  persistor: RefreshTokenPersistor;
};

type OptionalDeps = {
  apiConfig: ApiConfig;
  refreshTokenScheduler: PeriodicScheduler;
  credentials: Writable<RD.RemoteData<unknown, O.Option<Credentials>>>;
  tokenRefresher: TokenRefresher;
  authApi: AuthApi;
  meApi: MeApi;
  meState: Writable<RD.RemoteData<RecoverableError, Me>>;
};

type Deps = RequiredDeps & Partial<OptionalDeps>;

const fifteenMinutesInMs = 1000 * 60 * 15;

export const Auth = ({
  persistor,
  apiConfig = globalApiConfig,
  authApi = AuthApi(),
  meApi = MeApi(),
  credentials = writable(RD.initial),
  meState = writable(RD.initial),
  tokenRefresher: refresher = TokenRefresher(
    getCredentials({ store: credentials, persistor }),
    authApi.renew,
  ),
  refreshTokenScheduler = PeriodicScheduler(
    () => void refresher.tick(),
    fifteenMinutesInMs,
  ),
}: Deps): Auth => {
  const auth: Auth = {
    me: derived(meState, RD.toOption),
    loginState: derived([credentials, meState], ([store, me]) =>
      pipe(
        store,
        RD.map(constVoid),
        RD.chain(() =>
          pipe(me, RD.mapLeft(lookup("error")), RD.map(constVoid)),
        ),
      ),
    ),
    token: derived(
      credentials,
      flow(
        RD.toOption,
        O.flatten,
        O.map((credentials) => credentials.accessToken),
      ),
    ),
    init: async (location = window.location) => {
      registerEffects();
      const params = new URLSearchParams(location.search);
      const intent = params.get("intent");
      return intent === "login" ? loginFromUrl(auth, params) : refresher.tick();
    },
    login: (email, code) =>
      pipe(
        authApi.authenticate(email, code),
        TE.map(O.some),
        mapRemoteData(credentials.set),
        TE.map(constVoid),
      ),

    logout: async () => {
      meState.set(RD.initial);
      credentials.set(RD.initial);
      await persistor.store("");
    },
    renew: refresher.renew,
  };

  refresher.delegate = createRefresherDelegate(auth, credentials);

  return auth;

  function registerEffects() {
    credentials.subscribe((creds) => {
      if (!RD.isSuccess(creds)) return;
      void pipe(
        creds.value,
        O.map((c) => c.refreshToken),
        O.getOrElse(() => ""),
        persistor.store,
      );
    });
    auth.token.subscribe(
      O.fold(refreshTokenScheduler.stop, refreshTokenScheduler.start),
    );
    auth.token.subscribe(
      O.fold(
        () => apiConfig.setToken(""),
        (token) => {
          apiConfig.setToken(token);
          if (!RD.isSuccess(get(meState))) void loadMe();
        },
      ),
    );
  }

  async function loadMe() {
    await pipe(
      meApi.fetchMe(),
      TE.mapLeft((error) => ({ error, retry: loadMe })),
      mapRemoteData(meState.set),
    )();
  }
};

const loginFromUrl = async (
  auth: Pick<Auth, "login" | "logout">,
  params: URLSearchParams,
) => {
  await auth.logout();
  const email = params.get("email");
  const code = params.get("code");

  if (!email || !code) return;
  await auth.login(email, code)();
};

const createRefresherDelegate = (
  auth: Pick<Auth, "logout">,
  store: Required<Deps>["credentials"],
): TokenRefreshDelegate => {
  const getCredentials = () => pipe(store, get, RD.toOption, O.flatten);

  return {
    credentialsChanged: (
      nextCredentials: RD.RemoteData<unknown, O.Option<Credentials>>,
    ) => {
      const credentials = getCredentials();
      /**
       * At startup we have two use cases, 1. we have credentials stored and we auto login and 2. when entering the login code.
       * And then after app startup, we refresh periodically the access token.
       * The refresher delegate is used only for 1. (using `refresher.tick()`) and refreshing periodically (every 15 min.)
       * `nextCredentials` are the next credentials returned by the token refresher, `RemoteData<Err, Option<Credentials>>`
       * `store` credentials are the auth credentials stored in the model and is used by the app to determine if the user is logged in.
       *
       * Empty `store` credentials has a different meaning than empty `nextCredentials`:
       * - Empty `store` credentials => auth situation is resolved and user is unlogged
       * - Empty `nextCredentials` => no change, noop.
       *
       * When `nextCredentials` is
       * - initial -> credentials should be set to initial (can happen only at startup)
       * - pending -> if we currently have credentials the app is started. We use those to avoid displaying an auth-loading screen which would disturb the user session. Otherwise it's page startup, in that case we want to display the auth-loading screen.
       * - failure -> logout (handles more than just setting credentials to none)
       * - success
       *   - if none -> credentials were not renewed, nothing to do
       *   - if some -> update credentials
       */
      pipe(
        nextCredentials,
        RD.fold<unknown, O.Option<Credentials>, void>(
          () => store.set(RD.initial),
          () =>
            store.set(
              O.isSome(credentials) ? RD.success(credentials) : RD.pending,
            ),
          auth.logout,
          O.fold(constVoid, flow(O.some, RD.success, store.set)),
        ),
      );
    },
  };
};

function getCredentials({
  store,
  persistor,
}: {
  store: Readable<RD.RemoteData<unknown, O.Option<Credentials>>>;
  persistor: RefreshTokenPersistor;
}) {
  return async (): Promise<
    Pick<Credentials, "refreshToken" | "accessTokenExpirationDate"> | undefined
  > => {
    const c1 = getCredentialsFromStore(store);
    if (c1) return c1;

    const c2 = await getCredentialsFromPersistor(persistor);
    if (c2) return { ...c2, accessTokenExpirationDate: -1 };
    return undefined;
  };
}

function getCredentialsFromStore(
  store: Readable<RD.RemoteData<unknown, O.Option<Credentials>>>,
): Credentials | undefined {
  return pipe(store, get, RD.toOption, O.flatten, O.toUndefined);
}

async function getCredentialsFromPersistor(
  persistor: RefreshTokenPersistor,
): Promise<Pick<Credentials, "refreshToken"> | undefined> {
  const token = await persistor.retrieve();
  return token ? { refreshToken: token } : undefined;
}
