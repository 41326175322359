export interface PeriodicScheduler {
  start: () => void;
  stop: () => void;
}
class PeriodicSchedulerImpl implements PeriodicScheduler {
  private interval: number | undefined;

  constructor(
    private readonly fn: () => void,
    private readonly ms: number,
  ) {}

  start = (): void => {
    this.stop();
    this.interval = setInterval(this.fn, this.ms) as unknown as number;
  };
  stop = (): void => {
    clearInterval(this.interval);
    this.interval = undefined;
  };
}

export const PeriodicScheduler = (
  ...params: ConstructorParameters<typeof PeriodicSchedulerImpl>
): PeriodicScheduler => new PeriodicSchedulerImpl(...params);
