import {
  constVoid,
  E,
  identity,
  mapRemoteData,
  O,
  pipe,
  RD,
  T,
  TE,
} from "@seekdharma/std";
import type { AuthApi } from "./AuthApi";
import type { Credentials } from "./Credentials";

export type TokenRefreshDelegate = {
  credentialsChanged: (
    credentials: RD.RemoteData<unknown, O.Option<Credentials>>,
  ) => void;
};

export interface TokenRefresher {
  set delegate(delagate: TokenRefreshDelegate | undefined);

  tick: () => Promise<void>;
  renew: () => Promise<void>;
}

class TokenRefresherImpl implements TokenRefresher {
  private _delegate: TokenRefreshDelegate | undefined;

  set delegate(delegate: TokenRefreshDelegate | undefined) {
    this._delegate = delegate;
  }

  constructor(
    private readonly getCredentials: () => Promise<
      | Pick<Credentials, "refreshToken" | "accessTokenExpirationDate">
      | undefined
    >,
    private readonly renewRequest: AuthApi["renew"],
  ) {}

  private maybeRenew = ({ forcefully }: { forcefully: boolean }) =>
    pipe(
      TE.tryCatch(async () => {
        const credentials = await this.getCredentials();
        if (!credentials) return E.left("unauthenticated" as unknown);

        const { accessTokenExpirationDate, refreshToken } = credentials;
        if (!forcefully && !isExpiredOrAboutToExpire(accessTokenExpirationDate))
          return E.right(O.none);

        return pipe(this.renewRequest(refreshToken), TE.map(O.some))();
      }, identity),
      TE.chainW(TE.fromEither),
    );

  renew = () =>
    pipe(
      this.maybeRenew({ forcefully: true }),
      TE.chainFirstIOK((credentials) => () => {
        this._delegate?.credentialsChanged(RD.success(credentials));
      }),
      T.map(constVoid),
    )();

  tick = () =>
    pipe(
      this.maybeRenew({ forcefully: false }),
      mapRemoteData(this._delegate?.credentialsChanged || constVoid),
      T.map(constVoid),
    )();
}

export const TokenRefresher = (
  ...params: ConstructorParameters<typeof TokenRefresherImpl>
): TokenRefresher => new TokenRefresherImpl(...params);

function isExpiredOrAboutToExpire(expirationDate: number): boolean {
  return expirationDate < Date.now() + 600000;
}
