import { ids } from "@seekdharma/common-types";
import { C, DC, O, pipe } from "@seekdharma/std";
import { derived, type Readable } from "svelte/store";
import { getAppContext } from "../AppContext";
import { getAuthenticatedContext } from "../AuthenticatedContext";
import { Router } from "../lib/router";
import { routerToSvelteRouter } from "../lib/router-svelte";
import type { TripByPipelineIdPageModel } from "./pages/TripByPipelineIdPage/TripByPipelineIdPage";

/**
 * In this portal the user must be authenticated, whatever their role.
 * Therefore, consuming the authenticated is fine – and encouraged.
 * Caution: it's up to the pages to differentiate what to present to whom.
 * Bottom line: Beware of GDPR !
 */
export interface QuickAccessPortal {
  readonly basePath: string;
  readonly route: Readable<O.Option<Route>>;
  readonly email: Readable<string>;
}

type Route = {
  name: "tripByPipelineId";
  id: ids.TripId;
  model: () => Promise<TripByPipelineIdPageModel>;
};

type Deps = {
  basePath: string;
};

export const QuickAccessPortal = ({ basePath }: Deps) => {
  const { history } = getAppContext();
  const { me } = getAuthenticatedContext();

  const router = Router<Route>({
    history,
    basePath,
    isSameRoute,
    routes: {
      "/monday-trip/:id": ({ params }) =>
        pipe(
          params.id,
          pipe(DC.numberFromString, C.compose(ids.TripIdCodec)).decode,
          O.fromEither,
          O.map(
            (id): Route => ({
              name: "tripByPipelineId",
              id,
              model: async () => {
                const m = await import(
                  "./pages/TripByPipelineIdPage/TripByPipelineIdPage"
                );
                return m.TripByPipelineIdPage({ tripPipelineId: id });
              },
            }),
          ),
        ),
    },
  });

  const { active } = routerToSvelteRouter(router);

  const portal: QuickAccessPortal = {
    basePath,
    email: derived(me, (me) => me.email),
    route: active,
  };
  return portal;
};

const isSameRoute = (a: Route, b: Route): boolean => {
  switch (a.name) {
    case "tripByPipelineId":
      return b.name === "tripByPipelineId" && a.id === b.id;
    default:
      return a.name === b.name;
  }
};
