import { mapRemoteData, pipe, RD, TE } from "@seekdharma/std";
import type { SignUpPayload } from "@seekdharma/studio-shared";
import { writable, type Readable, type Writable } from "svelte/store";

export interface SignUpPage {
  readonly form: Writable<SignUpPayload>;
  readonly submitState: Readable<RD.RemoteData<unknown, void>>;
  input: Record<
    "firstName" | "lastName" | "email",
    HTMLInputElement | undefined
  >;
  submit: (values: SignUpPayload) => Promise<void>;
}

type Deps = {
  email?: string;
  signUp: (form: SignUpPayload) => TE.TaskEither<unknown, void>;
};
export const SignUpPage = ({ signUp, email }: Deps): SignUpPage => {
  const submitState = writable<RD.RemoteData<unknown, void>>(RD.initial);
  const page: SignUpPage = {
    form: writable({
      firstName: "",
      lastName: "",
      email: email ?? "",
      socials: [{ name: "instagram", value: "" }],
    }),
    submitState,
    input: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
    },
    submit: async (values) => {
      if (!Object.values(page.input).every(isInputValid)) return;
      await pipe(
        signUp({
          ...values,
          socials: values.socials.filter((social) => social.value),
        }),
        mapRemoteData(submitState.set),
      )();
    },
  };
  return page;
};

const isInputValid = (input: HTMLInputElement | undefined) => {
  return !input || input.validity.valid;
};
