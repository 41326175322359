<script lang="ts">
  import { RD } from "@seekdharma/std";
  import { fly } from "svelte/transition";
  import SocialChannelsControl from "../../components/SocialChannelsControl.svelte";
  import { Feedback } from "../../ui-kit";
  import type { SignUpPage } from "./SignUpPage";

  export let model: SignUpPage;
  const { form, submitState } = model;
  let submitted = false;
  const submit = () => {
    submitted = true;
    void model.submit($form);
  };
  const markSubmitted = () => {
    submitted = true;
  };
</script>

<div class="flex column gap-xl" in:fly|global={{ x: "-150%", y: 0 }}>
  <form class="flex column gap-s" on:submit|preventDefault={submit}>
    <div class="grid gap-xs">
      <div class="cell 2/4 form-field">
        <input
          class="control"
          bind:value={$form.firstName}
          bind:this={model.input.firstName}
          placeholder="First Name"
          required
          on:invalid|preventDefault={markSubmitted}
        />
        {#if submitted && !model.input.firstName?.validity.valid}
          <Feedback kind="danger" class="w-100">
            Please enter a first name
          </Feedback>
        {/if}
      </div>
      <div class="cell 2/4 form-field">
        <input
          class="control"
          bind:value={$form.lastName}
          bind:this={model.input.lastName}
          placeholder="Last Name"
          required
          on:invalid|preventDefault={markSubmitted}
        />
        {#if submitted && !model.input.lastName?.validity.valid}
          <Feedback kind="danger" class="w-100">
            Please enter a last name
          </Feedback>
        {/if}
      </div>
    </div>

    <div class="form-field">
      <input
        type="email"
        class="control"
        bind:value={$form.email}
        bind:this={model.input.email}
        placeholder="Email Address"
        required
        on:invalid|preventDefault={markSubmitted}
      />
      {#if submitted && !model.input.email?.validity.valid}
        <Feedback kind="danger" class="w-100">
          Please enter a valid email
        </Feedback>
      {/if}
    </div>

    <div class="px-s"><hr /></div>

    <div class="form-field text-left">
      <div>
        <SocialChannelsControl hideLabels bind:value={$form.socials} />
      </div>
      <small class="dark-grey-text">Optional</small>
    </div>

    <button
      class="button w-100"
      type="submit"
      disabled={RD.isPending($submitState)}
    >
      Continue
    </button>

    {#if RD.isFailure($submitState)}
      <Feedback kind="danger">
        Something went wrong, please try again or try logging in
      </Feedback>
    {/if}
  </form>

  <div class="flex column gap-xxxs dark-grey-text">
    <div>
      We’ll send a code to your email inbox that you’ll need to continue.
    </div>
    <div>
      {"By registering, you agree to our "}
      <a
        class="link"
        href="https://seekdharma.com/booking-conditions-seek-dharma/"
        target="_blank"
        rel="noreferrer noopener"
      >
        {"T&C."}
      </a>
    </div>
  </div>
</div>
