import { C, Fetch, parseJsonResponse, pipe, TE } from "@seekdharma/std";
import { Profile } from "@seekdharma/studio-shared";
import { apiConfig } from "../ApiConfig";
import { singleton } from "../lib/memo";
import type { Me } from "./Me";

export interface MeApi {
  fetchMe: () => TE.TaskEither<unknown, Me>;
}

export const MeApi = singleton((): MeApi => {
  const fetch = Fetch(apiConfig.basePath, () => ({
    headers: { authorization: apiConfig.getToken() },
  }));
  return {
    fetchMe: () => pipe(fetch("/me"), TE.chainW(parseJsonResponse(MeCodec))),
  };
});

const MeCodec = C.struct({
  email: C.string,
  profiles: C.array(Profile),
});
