import { Fetch, parseJsonResponse, pipe, TE } from "@seekdharma/std";
import { Configuration } from "@seekdharma/studio-shared";
import { apiConfig } from "./ApiConfig";

export interface ConfigApi {
  getConfig: () => TE.TaskEither<unknown, Configuration>;
}

export const ConfigApi = (): ConfigApi => {
  const fetch = Fetch(`${apiConfig.basePath}`);

  return {
    getConfig: () =>
      pipe(fetch("/config"), TE.chainW(parseJsonResponse(Configuration))),
  };
};
