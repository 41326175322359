<script lang="ts">
  import { RD } from "@seekdharma/std";
  import { Feedback } from "../../ui-kit";
  import type { EnterLoginCodeFormPage } from "./EnterLoginCodeFormPage";

  export let model: EnterLoginCodeFormPage;
  export let goBack: () => void;

  $: code = model.code;
  $: submitState = model.submitState;
  $: resendState = model.resendState;
  $: submit = () => void model.submit($code);

  const resend = () => void model.resend();
</script>

<div class="flex column gap-xl">
  <form on:submit|preventDefault={submit} class="flex column gap-s">
    <input
      type="text"
      name="code"
      class="control"
      placeholder="Code, ie: 123456"
      bind:value={$code}
      required
    />

    <div class="flex column gap-xs">
      <button class="button w-100" disabled={RD.isPending($submitState)}>
        Authenticate
      </button>

      {#if RD.isFailure($submitState)}
        <Feedback kind="danger">
          Something went wrong, please try again
        </Feedback>
      {/if}
    </div>
  </form>

  <div class="dark-grey-text text-center">
    Didn’t receive the code? <br />
    <button
      class="link"
      on:click={resend}
      disabled={RD.isPending($resendState)}
    >
      Resend
    </button>
    {" or "}
    <button class="link" on:click={goBack}>
      Send code to another email address
    </button>
  </div>
</div>
