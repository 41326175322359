import type { tripBrief } from "@seekdharma/common-types";
import {
  apply,
  constVoid,
  flow,
  mapRemoteData,
  RD,
  task as T,
  TE,
} from "@seekdharma/std";
import type {
  CommunitySurveyConfig,
  Configuration,
  FeatureFlags,
  MarketplaceConfig,
} from "@seekdharma/studio-shared";
import { derived, writable, type Readable } from "svelte/store";
import type { ConfigApi } from "./ConfigApi";
import type { RecoverableError } from "./lib/error";

export interface Config {
  readonly featureFlags: Readable<
    RD.RemoteData<RecoverableError, FeatureFlags>
  >;
  readonly communitySurvey: Readable<
    RD.RemoteData<RecoverableError, CommunitySurveyConfig>
  >;
  readonly tripBrief: Readable<
    RD.RemoteData<RecoverableError, tripBrief.Config>
  >;
  readonly marketplace: Readable<
    RD.RemoteData<RecoverableError, MarketplaceConfig>
  >;
  load: () => Promise<unknown>;
}

export const Config = (deps: { api: ConfigApi }): Config => {
  const config = writable<RD.RemoteData<RecoverableError, Configuration>>(
    RD.initial,
  );

  const model: Config = {
    featureFlags: derived(
      config,
      RD.map((c) => c.features),
    ),
    communitySurvey: derived(
      config,
      RD.map((c) => c.communitySurvey),
    ),
    marketplace: derived(
      config,
      RD.map((c) => c.marketplace),
    ),
    tripBrief: derived(
      config,
      RD.map((c) => c.tripBrief),
    ),
    load: flow(
      deps.api.getConfig,
      TE.mapLeft((error) => ({ error, retry: () => model.load() })),
      mapRemoteData(config.set),
      T.map(constVoid),
      apply(constVoid()),
    ),
  };

  return model;
};
