import {
  C,
  constVoid,
  refinement as Cr,
  Fetch,
  parseJsonResponse,
  pipe,
  TE,
} from "@seekdharma/std";
import { SignUpPayload } from "@seekdharma/studio-shared";
import { apiConfig } from "../ApiConfig";
import type { Credentials } from "./Credentials";

export interface AuthApi {
  authenticate: (
    email: string,
    code: string,
  ) => TE.TaskEither<unknown, Credentials>;
  requestCode: (email: string) => TE.TaskEither<unknown, void>;
  signUp: (payload: SignUpPayload) => TE.TaskEither<unknown, void>;
  renew: (refreshToken: string) => TE.TaskEither<unknown, Credentials>;
}

export const AuthApi = (): AuthApi => {
  const fetch = Fetch(`${apiConfig.basePath}/auth`);
  return {
    requestCode: (email) =>
      pipe(
        fetch("/requestCode", {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: { "Content-Type": "application/json" },
        }),
        TE.map(constVoid),
      ),

    signUp: (payload) =>
      pipe(
        fetch("/sign-up", {
          method: "POST",
          body: JSON.stringify(SignUpPayload.encode(payload)),
          headers: { "Content-Type": "application/json" },
        }),
        TE.map(constVoid),
      ),

    authenticate: (email, code) =>
      pipe(
        fetch("/authenticate", {
          method: "POST",
          body: JSON.stringify({ email, code }),
          headers: { "Content-Type": "application/json" },
        }),
        TE.chainW(parseJsonResponse(ResponseCodec)),
      ),

    renew: (refreshToken) =>
      pipe(
        fetch("/renew", {
          method: "POST",
          body: JSON.stringify({ refreshToken }),
          headers: { "Content-Type": "application/json" },
        }),
        TE.chainW(parseJsonResponse(ResponseCodec)),
      ),
  };
};

const ResponseCodec = C.struct({
  accessToken: pipe(C.string, Cr.minLength(1)),
  accessTokenExpirationDate: C.number,
  refreshToken: pipe(C.string, Cr.minLength(1)),
  refreshTokenExpirationDate: C.number,
});
