/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpError, O, pipe } from "@seekdharma/std";
import Rollbar from "rollbar";
import { derived, get, type Readable } from "svelte/store";
import type { Me } from "./auth-portal";
import { DemoError, LoadModuleFailed } from "./lib/error";

export type ErrorReporter = {
  reportOrLog: (err: unknown) => void;
};

export const ErrorReporter = (
  deviceId: string,
  sessionId: string,
  me: Readable<O.Option<Me>>,
): ErrorReporter => {
  const codeVersion = process.env.CODE_VERSION;
  const environment = process.env.ENVIRONMENT;
  if (!codeVersion) throw new Error("missing code version");
  if (!environment) throw new Error("missing environment");

  const options = derived(
    me,
    (me): Rollbar.Configuration => ({
      accessToken: "f3b826cab1314c50bd8311526ca5f741",
      captureUncaught: true,
      captureUnhandledRejections: true,
      codeVersion,
      environment,
      enabled: environment !== "development",
      payload: {
        client: {
          javascript: {
            code_version: codeVersion,
            source_map_enabled: true,
          },
        },
        person: pipe(
          me,
          O.map((user) => ({
            id: 0,
            email: user.email,
            profiles: user.profiles,
            sessionId,
            deviceId,
          })),
          O.getOrElseW(() => ({
            id: 0,
            email: "Anonymous user",
            deviceId,
            sessionId,
          })),
        ),
      },
    }),
  );

  const rollbar = new Rollbar(get(options));
  options.subscribe((nextOptions) => rollbar.configure(nextOptions));

  return {
    reportOrLog: (error) => {
      const level = getErrorLogLevel(error);
      rollbar.options.enabled && level && isReportableError(error)
        ? rollbar[level](error)
        : console.error(error);
    },
  };
};

const isReportableError = (err: unknown): err is Error | string =>
  !!(err && (err instanceof Error || typeof err === "string"));

const getErrorLogLevel = (
  err: unknown,
): "warn" | "info" | "error" | undefined => {
  if (err instanceof HttpError) return err.code < 500 ? "warn" : "error";
  if (err instanceof LoadModuleFailed) return "info";
  if (err instanceof DemoError) return undefined;
  return "error";
};
