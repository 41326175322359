import { mapRemoteData, pipe, RD, TE } from "@seekdharma/std";
import { writable, type Readable, type Writable } from "svelte/store";

export interface RequestLoginCodeFormPage {
  readonly email: Writable<string>;
  readonly submitState: Readable<RD.RemoteData<unknown, void>>;
  submit: (email: string) => Promise<void>;
}

type Deps = {
  email?: string;
  requestCode: (email: string) => TE.TaskEither<unknown, void>;
};

export const RequestLoginCodeFormPage = ({ requestCode, email }: Deps) => {
  const submitState = writable<RD.RemoteData<unknown, void>>(RD.initial);
  const page: RequestLoginCodeFormPage = {
    email: writable(email ?? ""),
    submitState,
    submit: async (email: string) => {
      await pipe(requestCode(email), mapRemoteData(submitState.set))();
    },
  };
  return page;
};
