<script lang="ts">
  import { RD } from "@seekdharma/std";
  import { fly } from "svelte/transition";
  import { Feedback } from "../../ui-kit";
  import type { RequestLoginCodeFormPage } from "./RequestLoginCodeFormPage";

  export let model: RequestLoginCodeFormPage;
  let submitted = false;
  $: email = model.email;
  $: submitState = model.submitState;
  $: submit = () => {
    submitted = true;
    void model.submit($email);
  };
</script>

<div class="flex column gap-xl" in:fly|global={{ x: "-150%", y: 0 }}>
  <form on:submit|preventDefault={submit} class="flex column gap-s">
    <div class="form-field">
      <input
        type="email"
        name="email"
        class="control"
        placeholder="Enter your email"
        bind:value={$email}
        required
      />
      {#if submitted && !email}
        <Feedback kind="danger" class="w-100">Please enter an email</Feedback>
      {/if}
    </div>

    <div class="flex justify-center">
      <button class="button w-100" disabled={RD.isPending($submitState)}>
        Continue
      </button>
    </div>

    {#if RD.isFailure($submitState)}
      <Feedback kind="danger">
        Something wrong occurred, please try again
      </Feedback>
    {/if}
  </form>

  <div class="dark-grey-text">
    We’ll send a code to your email inbox that you’ll need to continue.
  </div>
</div>
