import type { RefreshTokenPersistor } from "../auth-portal/RefreshTokenPersistor";

class LocalStorageRefreshTokenPersistorImpl implements RefreshTokenPersistor {
  store = (token: string): Promise<void> => {
    localStorage.setItem("refreshToken", token);
    return Promise.resolve(undefined);
  };
  retrieve = (): Promise<string | undefined> => {
    const token = localStorage.getItem("refreshToken") ?? undefined;
    return Promise.resolve(token);
  };
}

export const LocalStorageRefreshTokenPersistor = (): RefreshTokenPersistor =>
  new LocalStorageRefreshTokenPersistorImpl();
